@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body{
  background-color: #f7eee7;
}

.glass {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.glassfooter {
  background: rgba(159, 128, 103, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=DM+Serif+Display:ital@0;1&family=Fjalla+One&family=Instrument+Serif:ital@0;1&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pattaya&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');

@font-face {
    font-family: "seasons";
    src: url('./assets/seasons.woff2');
    font-style: normal;
}

@font-face {
  font-family: "seasons2";
  src: url('https://dm90w54v7z2d.cloudfront.net/seasons.woff2');
  font-style: normal;
}

.arvo {
    font-family: "Arvo", serif;
  }
  
.seasons {
    font-family: "seasons";
  }

  .seasons2 {
    font-family: "seasons2";
  }


@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fade-in {
    animation: fade-in 0.7s ease-out forwards;
  }
  


  .carousel-container {
    position: relative;
    width: 90%;
    height: 50vh;
    overflow: hidden;
    margin: auto;
  }
  
  .carousel {
    display: flex;
    position: relative;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    min-width: 100%;
    height: 100%;
    clip-path: circle(50% at center);
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
  }
  
  .carousel-controls {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
  }
  
  .carousel-button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
  }
  
  .carousel-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }